import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';
import {Helmet} from 'react-helmet';

const StaticHealth: React.FunctionComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          buildTime
        }
      }
    `}
    render={(data: {site: {buildTime: string}}) => (
      <div>
        <Helmet>
          <title>My Food Bag - Health</title>
          <meta name="Health Check" />
        </Helmet>
        <div>
          <h1>Health Check</h1>
          <p>{"If you can see this, then we're alive!"}</p>
          <p>This site was last updated at {data.site.buildTime}.</p>
        </div>
      </div>
    )}
  />
);

export default StaticHealth;
